import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {ValidacionIdentificacion} from '../validations/NumerosIdentificacion.js'
import ValidationMessage from '../validations/ValidationMessage.js'

export const Login = () => {

	const navigate = useNavigate();
	const [usuario, setUsuario] = useState('');
	const [message, setMessage] = useState('');
	const validationMessage = new ValidationMessage();

	const handleOnChangeInputUser = e => {
        setUsuario(e.target.value)
    }

	const envia = async (e) => {
		// Para que no se recargue la pagina al hacer click
		e.preventDefault();
		//
		let target = e.target;
		const $ = e => document.querySelector(e);
		const loginSpan = $('#login-form span');

		//let username = target.username.value;
		let password = target.password.value;

		const validation = ValidacionIdentificacion.ComprobarNombreDocument(usuario);

		setUsuario(validation.user);
		setMessage(validationMessage.getMessage(validation.idValidacion));
		
		if (validation.idValidacion === 1 || validation.idValidacion === 3) {
			fetchLogin(validation.user);
		} else {
			loginSpan.innerText = message
		}

		async function fetchLogin(user) {
			const options = {
				method: 'POST',
				body: JSON.stringify({ user, password }),
				headers: { "Content-type": "application/json; charset=UTF-8" },
			};

			fetch('/api/login', options)
				.then(async response => {
					const data = await response.json();
					// check for error response
					if (!response.ok) {
						// get error message from body or default to response status
						const error = (data && data.message) || response.status;
						return Promise.reject(error);
					} else {
						setTimeout(() => {
							navigate('/inicio', { state: { nif: user } })
						}, 2000)
					}
				})
				.catch(error => {
					loginSpan.innerText = 'Error en iniciar sessió';
				});
		};
	}

	return (
		<>
			<div className='form-container'>
				<form id='login-form' onSubmit={envia}>
					<h3>Connexió per a usuaris registrats</h3>
					<div className='info-login'>
						<p>Aquesta fase del procés NOMÉS està adreçada:</p>
						<ul>
							<li>
								<p>A les persones aspirants que han APROVAT l’examen de la prova d’oposició i la prova de coneixements de llengua catalana del procés d’Estabilització, fase 2 i fase 3.</p>
							</li>
						</ul>
						
					</div>
					{/*
					<div className='info-login'>
						<p>Aquesta fase del procés NOMÉS està adreçada:</p>
						<ul>
							<li>
								<p>A les persones aspirants que han APROVAT l'examen de la prova d'oposició del procés d'Estabilització, fase 2 i fase 3.</p>
							</li>
							<li>
								<p>En les categories de tècnic/a especialista de grau superior sanitari en anatomia patològica i citologia, tècnic/a especialista de grau superior sanitari en imatge per al diagnòstic i tècnic/a especialista de grau superior sanitari laboratori de diagnòstic clínic, s'adreça a les persones interessades que es van PRESENTAR a la prova d'oposició.</p>
							</li>
						</ul>
						<p>Cal comprovar la informació de mèrits introduïda, entre el 12 de juliol de 2023 i el 26 de juliol de 2023, clicant la "Descàrrega de Currículum", presentar la documentació acreditativa dels requisits i mèrits NOMÉS pendents de validar i informar, si s'escau, que s'aportarà certificat de serveis prestats NOMÉS TEMPORALS, en un centre no ICS adscrit al SISCAT.</p>
					</div>
					*/}
					<h6>Identifica't per iniciar la sessió.</h6>
					<input type="text" id="username" onChange={ handleOnChangeInputUser }
						placeholder="Document d'identitat" name="username" value={usuario}
						autoComplete="off" autoCapitalize="none" autoCorrect="off" required />

					<input type="password" id="password"
						placeholder='Clau' name="password"
						autoComplete="off" autoCapitalize="none" autoCorrect="off"
						aria-describedby="" required />

					<button type="submit">ACCEDIR</button>
					<br />
					<span>&nbsp;</span>
				</form>
			</div>
		</>

	)
}