class ValidationMessage {
    constructor() {
        this.messages = {
            0: "Error validación documento",
            1: "NIF ok",
            '-1': "Letra NIF error",
            3: "NIE ok",
            '-3': "NIE error"
        };
    }

    getMessage(id) {
        return this.messages[id] || "Mensaje no definido para este ID";
    }
}

export default ValidationMessage;