import React from 'react'
import { Routes, Route, BrowserRouter } from 'react-router-dom'
import { Login } from '../components/Login'
import { Error } from '../components/Error'
import { Inicio } from '../components/Inicio'

export const MainRouter = () => {
  return (
    <BrowserRouter>
        {/* Cargar componentes */}
        <section className='contenido-principal'>
            <Routes>
                <Route path='/' element={<Login />} />
                <Route path='/inicio' element={<Inicio />} />
                <Route path='*' element={<Error /> } />
            </Routes>
        </section>
    </BrowserRouter>
  )
}
