import React from 'react'
import { Link } from 'react-router-dom'

export const Error = () => {
  return (
    <div>
        <h1>Error 404</h1>
        <h3>No existe esta página</h3>
        <Link to='/'>Volver al inicio</Link>
    </div>
  )
}
